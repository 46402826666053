import React from 'react';

import Row from 'gatsby-theme-kalanso-ui/src/components/layout/Row';
import Col from 'gatsby-theme-kalanso-ui/src/components/layout/Col';

import Layout from './layout';
import AboveTheFold from './above-the-fold';
import BelowTheFold from './below-the-fold';
import Header from './header';
import Body from './body';

import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';

const IndoctrinationPage = ({ pageContext }) => {
  return (
    <Layout>
      <AboveTheFold>
        <Row justify="Center">
          <Col>
            <Header />
          </Col>
        </Row>

        <Row grow="1">
          <Col align="Center" className={`${_utils.fullWidth}`}>
            <Body {...pageContext} />
          </Col>
        </Row>
      </AboveTheFold>
      <BelowTheFold />
    </Layout>
  );
};

export default IndoctrinationPage;

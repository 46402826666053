import React from 'react';

import formatClassNames from '../../utils/formatClassNames'

import _defaultStyles from './text.module.scss';

const SubHeadline = ({ children, ...props }) => {
  const { className = '', ...rest } = props;

  const classes = formatClassNames([_defaultStyles.subheadline, className])

  return (
    <h2 className={classes} {...rest}>
      {children}
    </h2>
  );
};

export default SubHeadline;

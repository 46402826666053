import React from 'react';

import Headline from './headline';
import Subheadline from './sub-headline';

import _videoStyles from 'gatsby-theme-kalanso-ui/src/styles/video.module.scss';

const Body = ({ title, description, iframe }) => {
  return (
    <>
      <Headline title={title} />
      <Subheadline description={description} />

      <br />
      <br />

      <div
        className={`${_videoStyles.iframeWrapper}`}
        dangerouslySetInnerHTML={{ __html: iframe }}
      />

      <br />
      <br />
    </>
  );
};

export default Body;

import React from 'react';

import { LogoCentered } from 'kalanso-plugin-logos';

const Header = () => {
  return (
    <header>
      <LogoCentered/>
    </header>
  );
};

export default Header;
import React from 'react';

import Text from 'gatsby-theme-kalanso-ui/src/components/text/SubHeadline';

import _textStyles from 'gatsby-theme-kalanso-ui/src/components/text/text.module.scss';
import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';

import _subheadlineStyles from 'gatsby-theme-indoctrination-pages/src/components/sub-headline.module.scss';

const Subheadline = ({ description }) => {
  return (
    <Text
      className={`${_utils.fontSize300} ${_utils.fontWeight400} ${_textStyles.whiteColor} ${_subheadlineStyles.subheadline}`}
    >
      {description}
    </Text>
  );
};

export default Subheadline;

import React from 'react';

import Text from 'gatsby-theme-kalanso-ui/src/components/text/Headline';

import _textStyles from 'gatsby-theme-kalanso-ui/src/components/text/text.module.scss';
import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';

const Headline = ({ title }) => {
  return <Text className={`${_utils.fontSize100} ${_textStyles.whiteColor}`}>{title}</Text>;
};

export default Headline;
